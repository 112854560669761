.navbar {
  background-color: $custom-black;
  padding-right: 2rem;
/*  margin-bottom: 0;
  background-color: $custom-black-alpha;
  padding-right: 2rem;*/

}
.navbar-brand {
  padding-left: 1rem;
}

.navbar-inverse .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

body {
  padding-top: 5rem;
}

/* 
-------------------
ALBUM CSS (nested)
-------------------
*/

.album {
  overflow-x: hidden;
  height: 100%;
  padding-top: 0rem;
  .navbar {
    margin-bottom: 0;
    background-color: $custom-black-alpha;
    padding-right: 2rem;
  }

  .jumbotron {
    padding-top: 22%;
    padding-bottom: 22%;
    margin-bottom: 0;
    background-image: url("/assets/img/emcee-smile-podium-cropped.jpg");
    background-repeat: no-repeat;
    background-size:100% auto;
    min-height: 100%;
  }

  .jumbotron p:last-child {
    margin-bottom: 0;
  }

  .jumbotron-heading {
    font-weight: 300;
    bottom: 0;
  }

  .jumbotron .container {
    max-width: 40rem;
  }

/*  .album {
    min-height: 50rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #f7f7f7;
  }*/

  .card {
    float: left;
    width: 33.333%;
    padding: .75rem;
    margin-bottom: 2rem;
    border: 0;
  }

  .card > img {
    margin-bottom: .75rem;
  }

  .card-text {
    font-size: 85%;
  }
}

.ribbon {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: $custom-black;
  color: white;
/*  font-family: 'Passion One', cursive;
  font-family: 'News Cycle', sans-serif;*/
  font-family: 'Nunito', sans-serif;
}

.divpadding {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: lightgray;
}

.color-hr {
  background-color: goldenrod;
  height: .4rem;
  margin: 0;
}

.imagetron {
  padding-top: 6rem;
  padding-bottom: 6rem;
  margin-bottom: 3rem;
  text-align: left;
  background-repeat: no-repeat;
  background-size:100% auto;
  min-height: 100%;
  h1 {
    color: white;
    font-size: 4rem;
    font-variant: petite-caps;
  }
  .lead {
    color: white;
  }
}

.bio {
  background-image: url("/assets/img/audience-auditorium.jpg");
  text-align:center;
}

.booking {
  background-image: url("/assets/img/mic-abstract.jpg");
}

.testimonials {
  background-image: url("/assets/img/bevvys-jumbo-2.jpg");
}

.referrals {
  background-image: url("/assets/img/crowd-jumbotron.jpg");
}

.contact {
  background-image: url("/assets/img/pee-wee-stage.jpg");
  text-align:center;
}
.photos {
  background-image: url("/assets/img/pee-wee-stage.jpg");
}

footer {
  background-color: $custom-black;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 2.5rem
}

footer p {
  margin-bottom: .25rem;
  color: lightgray;
}

.social-icons {
  padding-left: .5rem;
  padding-right: .5rem;
}

.fa-facebook:hover {
  color:#3b5998;
}
.fa-twitter:hover {
  color:#1DA1F2;
}
.fa-linkedin:hover {
  color:#0077B5;
}

.img-caption {
  font-size:90%;
  color:#6c757d;
  margin:1rem;
  text-align:center
} 

/* 
--------------------------
CAROUSEL CSS (customized)
--------------------------
*/

/* Carousel base class */

.carousel {
  margin-bottom: 0;
  height: 36rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
  bottom: 3rem;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 36rem;
  background-color: #777;
}

// Carousel fade transition (https://codepen.io/zlobae/pen/xwVqGy)
.carousel-fade {
  .carousel-item {
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity .75s ease-in-out;

    &.active {
      opacity: 1;
    }
  }
}

.carousel-inner,
.carousel-item {
  width: 100%;
  height: 100%;
}

.carousel-item {
  &:nth-child(1) {
    background-image: url("/assets/img/smile-podium-cropped.jpg ");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100%;  
  }
  &:nth-child(2) {
    background-image: url("/assets/img/happy-tails-2.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100%;  
  }
  &:nth-child(3) {
    background-image: url("/assets/img/pee-wee-dancing.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100%;  
  }
}


/* HOME PAGE TYPOGRAPHY RESPONSIVENESS */

/* xs < 576 */
@include media-breakpoint-up(xs) {
  .display-4 {
    font-size: 1.5rem;
  }
}
/* sm 576 and up */
@include media-breakpoint-up(sm) {
  .display-4 {
    font-size: 2.0rem;
  }
}
/* md 768 and up */
@include media-breakpoint-up(md) {
  .display-4 {
    font-size: 2.5rem;
  }
}
/* lg 992 and up */
@include media-breakpoint-up(lg) {
  .display-4 {
    font-size: 3.0rem;
  }
}
/* lg 1200 and up */
@include media-breakpoint-up(xl) {
  .display-4 {
    font-size: 3.5rem;
  }
}

/* xs < 576 */
@include media-breakpoint-up(xs) {
  .display-3 {
    font-size: 2.5rem;
  }
}
/* md 768 and up */
@include media-breakpoint-up(md) {
  .display-3 {
    font-size: 3.5rem;
  }
}
/* lg 992 and up */
@include media-breakpoint-up(lg) {
  .display-3 {
    font-size: 4.0rem;
  }
}
/* lg 1200 and up */
@include media-breakpoint-up(xl) {
  .display-3 {
    font-size: 4.5rem;
  }
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

@media (max-width: 1199px) {
  .navbar-brand img {
    max-width: 100%;
    height: auto;
  }
  body {
    padding-top: 4.8rem;
  }
}

@media (max-width: 991px) {
  .navbar-brand img {
    max-width:25%;
    height: auto
  }
  body {
    padding-top: 4.6rem;
  }
}

@media (max-width: 767px) {
  .navbar-brand img {
    max-width:40%;
    height: auto;
  }
  body {
    padding-top: 4.3rem;
  }
}

@media (max-width: 575px) {
  .navbar-brand img {
    max-width:50%;
    height: auto;
  }
 }