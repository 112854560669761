$custom-font-size: 20px;

/* color theme hex codes */

$custom-light-tan: #DDDCC5;
$custom-dark-tan: #958976;
$custom-crimson: #611427;
$custom-black: #1D2326;
$custom-gray: #6A6A61;

$custom-black-alpha: rgba(29,35,38,.6);

@import "../bower_components/bootstrap/scss/variables";
@import url('https://fonts.googleapis.com/css?family=News+Cycle|Nunito|Passion+One');
